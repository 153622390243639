import React from 'react';
import { Link } from 'react-router-dom'; // Importar Link para navegación sin recarga
import '../BlogPage.css'; // Importa el nuevo CSS

const BlogPage = () => {
  const titleimagehalloween = require(`../images/titlehalloween.jpg`);
  const Winchester = require(`../images/winchester-house-02.jpg`);
  const Samhain = require(`../images/Neopagan_celebrating_Samhain.jpg`);
  const Pumpkins = require(`../images/Stingy-Jack.webp`);
  const Feng = require(`../images/fengshui.jpg`);
  const Marfa = require(`../images/marfa.jpeg`);
  const halloweenTrendAmazon = require(`../images/halloweenTrends.webp`);

  return (
    <div className="blog-page container">
      <header className="blog-header">
        <h1>Welcome to the Innovate Unboxed Blog</h1>
      </header>

      <main className="blog-content">
        {/* Usar Link para manejar la navegación */}
        <Link to="/blog/halloween-trends-amazon" className="read-more">
          <article className="blog-post">
            <img src={halloweenTrendAmazon} alt="Halloween Shopping Trends on Amazon" />
            <div className="blog-text">
              <h2>Halloween Shopping Trends on Amazon</h2>
              <p>Growth in Decorations, Costumes, and Candy Sales Driving Record Numbers</p>
            </div>
          </article>
        </Link>
        <Link to="/blog/marfa-texas" className="read-more">
          <article className="blog-post">
            <img src={Marfa} alt="Art that Harmonizes Your Home with Positive Energy" />
            <div className="blog-text">
              <h2>The Mystery of the Marfa Lights</h2>
              <p>For over a century, glowing orbs have appeared in the night sky near Marfa, Texas</p>
            </div>
          </article>
        </Link>
        <Link to="/blog/decoration-feng-shui" className="read-more">
          <article className="blog-post">
            <img src={Feng} alt="Art that Harmonizes Your Home with Positive Energy" />
            <div className="blog-text">
              <h2>Harmonizes Your Home with Positive Energy</h2>
              <p>Explore a selection of decorative pieces inspired by Feng Shui, designed to transform your space and enhance the flow of energy.</p>
            </div>
          </article>
        </Link>
       
        <Link to="/blog/stingy-jack" className="read-more">
          <article className="blog-post">
            <img src={Pumpkins} alt="The Story of Stingy Jack" />
            <div className="blog-text">
              <h2>The Spooky Origin of Halloween Pumpkins!</h2>
              <p>Discover the legend of Stingy Jack and how he tricked the devil to create the first Jack O' Lantern!</p>
            </div>
          </article>
        </Link>
        
        <Link to="/blog/samhain-bats-halloween" className="read-more">
          <article className="blog-post">
            <img src={Samhain} alt="The History of Bats in Halloween" />
            <div className="blog-text">
              <h2>The History of Bats in Halloween</h2>
              <p>From Samhain Rituals to Modern-Day Symbols of Mystery, Magic, and Halloween Traditions.</p>
            </div>
          </article>
        </Link>
        
        <Link to="/blog/winchester-mystery" className="read-more">
          <article className="blog-post">
            <img src={Winchester} alt="Mistery of the Winchester Mansion" />
            <div className="blog-text">
              <h2>The Haunted Secrets of Winchester Mansion</h2>
              <p>Explore the eerie story of Sarah Winchester and the mysterious mansion she built to escape the spirits that haunted her.</p>
            </div>
          </article>
        </Link>

        <Link to="/blog/halloween-2024-trends" className="read-more">
          <article className="blog-post">
            <img src={titleimagehalloween} alt="Halloween 2024" />
            <div className="blog-text">
              <h2>Halloween 2024 Trends You Can't Miss</h2>
              <p>Get ready to embrace Halloween 2024 with style! Follow these trends to make sure your home and your look steal the show this year.</p>
            </div>
          </article>
        </Link>

        
      </main>
    </div>
  );
};

export default BlogPage;
