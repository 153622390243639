// StarRating.js
import React from 'react';
import '../styles.css';

function StarRating({ rating, reviews }) {
  // Calcula cuántas estrellas llenas, medias y vacías mostrar
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 !== 0 ? 1 : 0;
  const emptyStars = 5 - fullStars - halfStars;

  return (
    <div className="star-rating">
      {/* Renderiza estrellas llenas */}
      {[...Array(fullStars)].map((_, index) => (
        <span key={`full-${index}`} className="star full">★</span>
      ))}
      {/* Renderiza media estrella si es necesario */}
      {halfStars > 0 && <span className="star half">★</span>}
      {/* Renderiza estrellas vacías */}
      {[...Array(emptyStars)].map((_, index) => (
        <span key={`empty-${index}`} className="star empty">☆</span>
      ))}
      {/* Mostrar el número de reseñas */}
      <span className="reviews">({reviews} reviews)</span>
    </div>
  );
}

export default StarRating;