// components/Footer.js
import React from 'react';
import '../styles.css'; // Importa el archivo de estilos del footer

function Footer() {
  return (
    <footer className="footer">
      <p>© {new Date().getFullYear()} Innovate-Unboxed. All rights reserved.</p>
    </footer>
  );
}

export default Footer;