// SearchBar.js
import React from 'react';
import '../SearchBar.css';
import { FaSearch } from 'react-icons/fa'; // Asegúrate de tener react-icons instalado

function SearchBar({ searchTerm, setSearchTerm }) {
  return (
    <div className="search-bar-container">
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search for products..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <FaSearch className="search-icon" />
      </div>
    </div>
  );
}

export default SearchBar;