import React, { useState, useEffect } from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import ProductGrid from './components/ProductGrid';
import ProductGridSearch from './components/ProductGridSearch';
import ProfileCard from './components/ProfileCard';
import PickOfTheDay from './components/PickOfTheDay';
import SearchBar from './components/SearchBar';
import Footer from './components/Footer';
import SideMenu from './components/SideMenu';
import BlogPage from './components/BlogPage'; 
import BlogArticlePage from './components/BlogArticlePage'; // Importar la nueva página del artículo
import ScrollToTop from './components/ScrollToTop'; // Importar el nuevo componente
import './styles.css';
import productsData from './products.json';

function App() {
  const [products, setProducts] = useState([]);
  const [pickOfTheDay, setPickOfTheDay] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    setProducts(productsData);

    const pick = productsData.find(product => product.category === 'pickoftheday');
    setPickOfTheDay(pick);
  }, []);

  const filteredProducts = products.filter(product =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Router>
      <ScrollToTop /> {/* Asegura que siempre hagamos scroll hacia arriba */}
      <div>
        <SideMenu />

        <header>
          <ProfileCard />
        </header>
        
        <Routes>
          <Route path="/" element={
            <div className="container">
              <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
              {searchTerm ? (
                <div className="search-results">
                  <h2>Search Results</h2>
                  {filteredProducts.length > 0 ? (
                    <ProductGridSearch products={filteredProducts} />
                  ) : (
                    <p>No results found.</p>
                  )}
                </div>
              ) : (
                <>
                  {pickOfTheDay && <PickOfTheDay product={pickOfTheDay} />}
                  <ProductGrid products={products} />
                </>
              )}
            </div>
          } />
          
          <Route path="/blog" element={<BlogPage />} />

          <Route path="/blog/:articleId" element={<BlogArticlePage />} />
        </Routes>

        <Footer />
      </div>
    </Router>
  );
}

export default App;