import React from 'react';
import '../styles.css';
import StarRating from './StarRating';

function PickOfTheDay({ product }) {
  const productImage = require(`../images/${product.image}`);

  return (
    <div className="pick-of-the-day">
      <h2>Pick of the Day</h2>
      <a href={product.url} target="_blank" rel="noopener noreferrer">
        <div className="product-details">
          <div className="product-image-container">
            <img src={productImage} alt={product.name} className="product-image" />
          </div>
          <div className="product-info">
            <h3>{product.name}</h3>
            <StarRating rating={product.rating} reviews={product.reviews} />
            <p>{product.description}</p>
            <p className="price">
              ${product.price} <span className="discount">- {product.discount}% off</span>
            </p>
            <p>
            <button className="buy-button">Buy Now</button>
            </p>

            {/* Sección de Comentarios sin comillas y con estilo moderno */}
            <div className="product-comments">
              <h4>Best Customer Reviews:</h4>
              {product.comments.map((comment, index) => (
                <div key={index} className="comment">
                  <strong>{comment.username}</strong>
                  <p>{comment.comment}</p>
                </div>
              ))}
            </div>

            
          </div>
        </div>
      </a>
    </div>
  );
}

export default PickOfTheDay;
