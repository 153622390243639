import React, { useState } from 'react';
import ProductCard from './ProductCard';
import '../styles.css';

function ProductGrid({ products, category }) {
  // Filtrar productos por categoría
  const filters = {
    Halloween: products.filter(product => product.category === "Halloween"),
    Discounts: products.filter(product => product.category === "Discounts"),
    'New Releases': products.filter(product => product.category === "New Releases"),
    'Best Seller': products.filter(product => product.category === "Best Seller"),
    Recommendations: products.filter(product => product.category === "Recommendations"),
  };

  // Controlar la cantidad de productos visibles por sección
  const [visibleProducts, setVisibleProducts] = useState({
    Discounts: 4,
    'New Releases': 4,
    'Best Seller': 4,
    Recommendations: 4,
    Halloween: 4,
  });

  const handleLoadMore = (category) => {
    setVisibleProducts((prevState) => ({
      ...prevState,
      [category]: prevState[category] + 4,
    }));
  };

  const handleShowLess = (category) => {
    setVisibleProducts((prevState) => ({
      ...prevState,
      [category]: 4,
    }));
  };

  const renderProductSection = (categoryProducts, categoryName) => {
    return (
      <div className="product-section" key={categoryName}>
        <h2>{categoryName}</h2>
        <div className="product-grid">
          {categoryProducts.slice(0, visibleProducts[categoryName]).map((product) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
        <div className="load-controls">
          {visibleProducts[categoryName] < categoryProducts.length && (
            <button className="load-more" onClick={() => handleLoadMore(categoryName)}>Load More</button>
          )}
          {visibleProducts[categoryName] > 4 && (
            <button className="show-less" onClick={() => handleShowLess(categoryName)}>Show Less</button>
          )}
        </div>
      </div>
    );
  };

  // Si se pasa una categoría específica, solo muestra esa
  if (category) {
    return <div className="product-grid-container">{renderProductSection(filters[category], category)}</div>;
  }

  // Si no se especifica categoría, muestra todas
  return (
    <div className="product-grid-container">
      {Object.keys(filters).map((categoryName) => renderProductSection(filters[categoryName], categoryName))}
    </div>
  );
}

export default ProductGrid;
