import React from 'react';
import { useParams } from 'react-router-dom';
import '../BlogArticlePage.css'; // CSS para los estilos de los artículos
import ProductGrid from './ProductGrid'; // Importar el ProductGrid
import productsData from '../products.json'; // Asegúrate de tener los productos disponibles

const BlogArticlePage = () => {
  const { articleId } = useParams(); // Obtener el ID del artículo desde la URL
  const Feng1 = require(`../images/71mrBmaxslL._AC_SL1063_.jpg`);
  const Feng2 = require(`../images/713LlrnvqnL._AC_SL1500_.jpg`);
  const Feng3 = require(`../images/411Nr3jgMUS._AC_SL1000_.jpg`);
  const Feng4 = require(`../images/71RXqJZ+ZtL._AC_SL1200_.jpg`);
  const hallo1 = require(`../images/ha1.png`);
  const hallo2 = require(`../images/ha2.png`);
  const hallo3 = require(`../images/ha3.png`);
  const hallo4 = require(`../images/ha4.png`);
  // Lista de artículos simulados
  const articles = {
    'halloween-trends-amazon': {
      title: 'Halloween Shopping Trends on Amazon',
      image: require(`../images/halloweenTrends.webp`),
      content: `
      <style>
      img.responsive {
        width: 100%;
        height: auto;
        max-width: 600px;
      }

      @media (max-width: 768px) {
        img.responsive {
          max-width: 400px;
          max-height: 400px;
        }
      }
    </style>
     <h1>Halloween Shopping Trends on Amazon (2021-2024)</h1>

    <p>Halloween has evolved from a simple holiday into a massive commercial event, especially in the United States. Consumers of all ages eagerly prepare for this date, driving Halloween product sales to record levels, particularly on Amazon, the leading online shopping platform. Over the past few years, sales of decorations, costumes, and candy have grown significantly, and this trend is expected to continue through 2024, reflecting consumers' strong interest in making the celebration bigger and better every year.</p>

    <h2>Decorations: The Heart of Halloween</h2>

    <p>Decorations have undoubtedly been one of the main drivers of Halloween shopping. Since 2021, spending on Halloween decorations has skyrocketed, growing from $2.9 billion in 2021 to $3.9 billion in 2023, with projections suggesting they will reach $4.2 billion by 2024. This category includes everything from LED pumpkin lights to spider webs and large inflatable figures that adorn homes and lawns.</p>

    <p>This increase reflects the trend of consumers turning Halloween into a more visual and social event. Neighborhoods often compete to have the most elaborate decorations, and this has been reflected in sales of decorative products, which are purchased by both families and young adults who enjoy hosting themed parties.</p>

    <h3>Sales growth and projection chart for decorations (2021-2024):</h3>
    <img src="${hallo1}"   alt="Decoration Sales Growth and Projection Chart" class="responsive">
      
    <h2>Costumes: From Tradition to Trend</h2>

    <p>Costumes have long been one of the most iconic aspects of Halloween, and it’s no surprise that they continue to be a major spending category. Between 2021 and 2023, costume sales on Amazon grew from $3.3 billion to $3.9 billion, with a projection of reaching $4.1 billion in 2024. Costumes inspired by iconic 90s movies and popular characters remain favorites, and we can't forget the rise of pet costumes, which surprise us more each year.</p>

    <p>It’s common to see dogs dressed as pirates, sharks, or even superheroes during the celebration, leading to annual sales of pet costumes exceeding $700 million.</p>

    <h3>Sales growth and projection chart for costumes (2021-2024):</h3>
    <img src="${hallo2}" alt="Costume Sales Growth and Projection Chart" class="responsive">


    <h2>Candy: The Essential Ingredient</h2>

    <p>No Halloween is complete without candy, and this is reflected in sales numbers. In 2023, Americans spent $3.8 billion on candy, up from $3.1 billion in 2021, and candy sales are expected to reach $4.0 billion by 2024. Top-selling brands include classics like Reese's, Skittles, and Starburst, which dominate Amazon’s best-seller lists. The trend of buying candy in bulk has grown, partly due to the increasing number of children participating in trick-or-treating and also because of parties that require large amounts of candy for guests.</p>

    <h3>Sales growth and projection chart for candy (2021-2024):</h3>
    <img src="${hallo3}" alt="Candy Sales Growth and Projection Chart" class="responsive">

    <h2>Total Halloween Sales: Continuous Growth</h2>

    <p>Overall, total Halloween sales have grown significantly in recent years, rising from $10.1 billion in 2021 to $12.2 billion in 2023, with a projection of reaching $13.0 billion in 2024. This growth reflects not only the popularity of the holiday but also consumers’ willingness to spend more on decorations, costumes, and candy.</p>

    <h3>Total sales growth and projection chart (2021-2024):</h3>
    <img src="${hallo4}" alt="Total Halloween Sales Growth and Projection Chart" class="responsive">

    <h2>Conclusions and Expectations for 2024</h2>

    <p>The trends of the past three years give us a clear view of what to expect for Halloween 2024. Interest in decorations will continue to grow, with a focus on products that offer interactive experiences, like lights and figures that react to movement. In terms of costumes, nostalgia will remain a key factor, and retro movie costumes are expected to continue dominating sales. Additionally, pet costumes will remain an upward trend.</p>

    <p>Finally, candy will continue to be an essential part of the celebration, with a focus on large packages and assortments to meet the demand for both parties and traditional trick-or-treating.</p>

    <p>Halloween is one of the most dynamic shopping seasons of the year, and more people are looking for creative ways to celebrate. If you're looking to capitalize on this trend, now is the time to prepare for 2024, ensuring you stay up to date on the latest products and trends in the market.</p>
      `,
      category:"Halloween",
      showProducts: true, // Bandera para mostrar productos de Halloween
    },
    'marfa-texas': {
      title: 'The Mystery of the Marfa Lights',
      image: require(`../images/marfa.jpeg`),
      content: `
     <h1>The Mystery of the Marfa Lights: An Enigmatic Dance in the Texas Desert</h1>

    <p>In the remote, arid desert of West Texas, an inexplicable phenomenon has captivated locals and visitors alike for over a century. Known as the "Marfa Lights," these mysterious orbs of light have appeared on the horizon, flickering and dancing across the vast landscape, baffling scientists, adventurers, and curious onlookers.</p>

    <p>The first recorded sighting of these lights dates back to 1883, when a young cowboy named Robert Reed Ellison spotted them while herding cattle through the desert. He described seeing strange, glowing spheres floating in the distance, moving erratically and disappearing into the night. At first, he thought they might be Apache campfires, but soon realized there were no signs of human presence nearby.</p>

    <p>Since then, dozens of people have reported seeing the lights. They appear as colored orbs—mainly white, red, and sometimes blue—that split apart, merge, and change direction with an agility that defies logical explanation. Often seen near Route 67, between the towns of Marfa and Alpine, the lights’ appearance is unpredictable, adding to the mystique of the phenomenon.</p>

    <p>Over the years, various theories have been proposed to explain the Marfa Lights. Some believe they are caused by the refraction of car headlights from the nearby highway or are optical illusions created by the area’s unique atmospheric conditions, such as layers of hot and cold air forming mirages. Others suggest that the lights are a form of electromagnetic activity, similar to the famous "St. Elmo’s Fire," a visible electrical discharge in the atmosphere during storms.</p>

    <p>However, these theories fail to explain sightings that occurred long before cars existed, nor do they account for the erratic and changing nature of the lights. Many witnesses insist that the lights follow no regular pattern and are not tied to specific weather conditions.</p>

    <p>Despite scientific explanations, the enigma of the Marfa Lights has sparked a wave of supernatural theories. Some believe the lights are manifestations of spirits or lost souls wandering the desert. Others, leaning toward the paranormal, suggest they could be UFOs or signals from extraterrestrial visitors. It’s not uncommon for witnesses to describe strange, unexplainable experiences or eerie sensations when observing the lights, fueling further speculation about their origin.</p>

    <p>Over time, the mystery of the Marfa Lights has evolved into a cultural phenomenon. Every year, thousands of people journey to Marfa, hoping to witness the strange spectacle for themselves. The town even built an observation platform dedicated to the lights, where visitors can scan the horizon for these elusive orbs. While some leave without seeing them, those lucky enough to catch a glimpse often leave in awe and fascination.</p>

    <p>The fact that the lights have defied conventional explanations for over a century only adds to their allure. For some, they represent a window into the unknown; for others, they are a reminder that there are phenomena in this world that still challenge our understanding of nature and the universe.</p>
      `,
      category:"Best Seller",
      showProducts: true, // Bandera para mostrar productos de Halloween
    },
    'halloween-2024-trends': {
      title: 'Halloween 2024 Trends You Can’t Miss',
      image: require(`../images/titlehalloween.jpg`),
      content: `
        <h1>Halloween 2024 Trends You Can’t Miss</h1>

        <p>With Halloween just around the corner, this year brings new and exciting trends that will make your decorations and costumes stand out more than ever. From nostalgic elements to the most innovative costumes, we’ll tell you what will be trending this Halloween 2024 and how you can prepare to impress.</p>

        <h2>1. Dark Nature-Inspired Decor</h2>
        <p>This year, the mystical and spooky merge in decorations inspired by nature. Elements like strange mushrooms, twisted plants, and sophisticated skulls are essential to create a unique and eerie atmosphere in your home. Dark and elegant details, like antique candelabras and black flowers, add a gothic touch without losing class. This trend mixes the mysterious with the natural, perfect for those looking for something beyond traditional decorations​ <em>(The Glossy Nest, The Spruce)</em>.</p>

        <h2>2. Vintage Decorations</h2>
        <p>Retro is back. Halloween decorations with a vintage touch, like black cats and old-fashioned witches, are making a comeback. These pieces bring a charming nostalgia that not only recalls Halloween classics but also adds warmth and authenticity to any space​ <em>(The Spruce)</em>.</p>

        <h2>3. Popular Costumes</h2>
        <p>Pop culture-inspired costumes are a must this year. Characters like Barbie and Ken, along with recreations of viral memes, will be everywhere. Additionally, group costumes for friends or families, such as the "Three Blind Mice" or "The Powerpuff Girls," will be trending at parties​ <em>(It’s Claudia G)</em>. So, get ready to be the center of attention with these outfits that will steal all the looks!</p>

        <h2>4. Chic Minimalism</h2>
        <p>While maximalism has dominated in past years, in 2024, minimalism is gaining ground. Simple yet elegant decorations, like white pumpkins and black candles in golden candelabras, will allow you to create a sophisticated and mysterious atmosphere without overwhelming the space​ <em>(The Spruce)</em>. This trend is ideal for those who prefer a more understated but impactful style.</p>
      `,
      category:"Halloween",
      showProducts: true, // Bandera para mostrar productos de Halloween
    },
    'decoration-feng-shui': {
      title: 'Harmonizes Your Home with Positive Energy',
      image: require(`../images/fengshui.jpg`),
      content: `
          <style>
          .responsive-img {
            width: 100%;
            max-width: 600px; /* Ajusta el tamaño máximo de la imagen */
            height: auto;
            display: block;
            margin: 0 auto;
          }

          .buy-button {
            display: inline-block;
            padding: 10px 20px;
            background-color: #f04e31;
            color: white; 
            text-align: center;
            border-radius: 5px;
            text-decoration: none;
            font-size: 16px;
            margin: 20px 0;
            display: block;
            width: 200px;
            margin-left: auto;
            margin-right: auto;
          }

          .buy-button:hover {
            background-color: #d13c27;
          }
        </style>
         
          <p>Explore a selection of decorative pieces inspired by Feng Shui, designed to transform your space and enhance the flow of energy.</p>

          <h2>Panel Modern Canvas Wall Art</h2>
          <p>This 3-panel modern canvas wall art features high-definition artwork printed on quality polyester canvas, perfect for adding a touch of elegance to any room. With a 4.6-star rating from over 9,400 satisfied customers and multiple size options available, this set is ready to hang with pre-attached hooks for easy installation. Ideal for living rooms, bedrooms, or offices, it brings a serene seascape to your space, offering great value with savings up to 60% and free international returns.</p>
        
          <div>
            <a href="https://amzn.to/4ejYGSa"><img src="${Feng1}" alt="Panel Modern Canvas Wall Art" class="responsive-img"></a>
            <a href="https://amzn.to/4ejYGSa" class="buy-button">Buy Now</a> 
          </div>
          <br>
          <h2>Wall Sconce Candle Holder Set</h2>
          <p>This set of two wall sconce candle holders offers a retro yet stylish touch to any room. With a 4.4-star rating from nearly 5,000 reviews, it’s a customer favorite. Each sconce features a metal openwork rectangular frame with a cylindrical plastic shade and a flameless LED light (batteries not included), adding a warm glow to your space. Suitable for living rooms, bathrooms, dining rooms, or entryways, these sconces are lightweight, easy to install, and make for a great decorative gift. Plus, they come with free international returns and up to 34% savings.</p>
          <div>
            <a href="https://amzn.to/3ztkyeZ"><img src="${Feng2}" alt="Panel Modern Canvas Wall Art" class="responsive-img"></a>
            <a href="https://amzn.to/3ztkyeZ" class="buy-button">Buy Now</a> 
          </div>
          <br>
          <h2>Eclipse of the Moon Modern Canvas Prints</h2>
          <p>This set of modern canvas prints, featuring the Eclipse of the Moon design, offers a stunning black-and-white abstract space theme to elevate any room's decor. With a 4.8-star rating from over 2,800 reviews and being an Amazon's Choice product, it's a popular pick for those looking to add a touch of sophistication to their home. The high-definition Giclee printing on premium canvas ensures sharp and vibrant imagery. Each panel is pre-stretched and comes with hooks for easy hanging. Ideal for living rooms, bedrooms, or offices, this set is available in multiple sizes and comes with free international returns.</p>
          <div>
            <a href="https://amzn.to/3XS6HIR"><img src="${Feng3}" alt="Panel Modern Canvas Wall Art" class="responsive-img"></a>
            <a href="https://amzn.to/3XS6HIR" class="buy-button">Buy Now</a>
          </div>
          <br>
          <h2>Golden and Blue Abstract Canvas Wall Art</h2>
          <p>This abstract canvas wall art, featuring a golden and blue river texture, brings a modern touch to any space. With a 4.6-star rating from over 2,400 reviews, it's a popular choice for enhancing the look of living rooms, bedrooms, or offices. The high-definition print on premium, waterproof canvas ensures long-lasting vivid colors and resistance to fading. Pre-installed hooks make it easy to hang right out of the box, with no need for adhesives. Available in multiple sizes, this unique artwork is perfect for creating a chic and cozy atmosphere, and it also makes a great gift for friends or family.</p>
          <div>
            <a href="https://amzn.to/3XIcMYb"><img src="${Feng4}" alt="Panel Modern Canvas Wall Art" class="responsive-img"></a>
            <a href="https://amzn.to/3XIcMYb" class="buy-button">Buy Now</a>
          </div>
          <br>
      `,
      category:"",
      showProducts: false, // Bandera para mostrar productos de Halloween
    },
    'stingy-jack':{
      title: 'The Spooky Origin of Halloween Pumpkins!',
      image: require(`../images/Stingy-Jack.webp`),
      content: `
        <h1>The Story of Stingy Jack</h1>

        <p>Once upon a time, there was a man named Jack, known in his village as "Stingy Jack" because he was extremely greedy and manipulative. One day, while drinking in a tavern, he encountered the devil. According to the legend, the devil had come to take his soul because of Jack’s bad behavior throughout his life.</p>

        <p>Being cunning and deceptive, Jack asked the devil to grant him one last wish: to enjoy a drink before dying. The devil agreed, and since Jack had no money to pay for the drink, he asked the devil to transform into a coin to pay the bartender. The devil complied and turned into a coin, but instead of using it to pay, Jack quickly put it into his pocket, where he also kept a silver cross. This prevented the devil from transforming back.</p>

        <p>Jack made a deal with the devil: he would set him free if the devil promised not to claim his soul for ten years. The devil agreed, and Jack let him go.</p>

        <h2>The Second Encounter</h2>

        <p>Ten years later, the devil came back to take Jack. This time, Jack, still cunning, asked the devil for one more favor before being taken: he asked the devil to climb a tree and pick some fruit. The devil agreed and climbed the tree, but while he was up there, Jack carved a cross into the tree trunk, trapping the devil in the branches and preventing him from coming down.</p>

        <p>Once again, Jack made a deal with the devil. This time, he asked the devil to never claim his soul. The devil, furious but with no choice, agreed.</p>

        <h2>Jack’s Fate</h2>

        <p>Years later, Jack died. When he tried to enter heaven, he was rejected because of his life of deception, sin, and greed. So, he went to hell, but the devil, remembering his promise, couldn’t take his soul. Jack was left trapped, with nowhere to go.</p>

        <p>The devil, mocking him, threw Jack a burning coal from hell to light his way as he wandered in eternal darkness. Jack placed the coal inside a hollowed-out turnip to protect the flame, creating a lantern. Since then, Jack has been condemned to wander the Earth with his turnip lantern, searching for a place to rest, becoming known as "Jack of the Lantern," or Jack O' Lantern.</p>

        <h2>Evolution of the Legend</h2>

        <p>Over time, the tradition of carving turnips and placing candles inside them as a symbol to ward off Jack and other wandering spirits became part of Halloween celebrations. When the tradition came to America, pumpkins—larger and easier to carve—replaced turnips, giving rise to the Jack O' Lanterns made from pumpkins that we know today.</p>

        <p>Thus, the legend of Stingy Jack not only tells the story of a man who tricked the devil, but also explains how one of Halloween's most popular traditions was born.</p>

      `,
      category:"Halloween",
      showProducts: true, // Bandera para mostrar productos de Halloween
    }
    ,
    'samhain-bats-halloween': {
      title: 'The History of Bats in Halloween',
      image: require(`../images/Neopagan_celebrating_Samhain.jpg`),
      content: `
        <h1>The History of Bats in Halloween</h1>
        <p>Bats have long been associated with darkness and mystery. However, their strong connection to Halloween has deep roots that trace back to the ancient Celtic festival of Samhain.</p>

        <h2>The Celtic Origin: Samhain</h2>
        <p>The Celts celebrated Samhain, a festival marking the end of the harvest season and the beginning of winter. This celebration, held on the night of October 31st, was believed to be a time when the veil between the world of the living and the dead was at its thinnest. Spirits, both good and evil, were thought to cross into the mortal world. To protect themselves from malevolent spirits, the Celts lit massive bonfires that blazed through the night.</p>

        <p>These fires attracted various insects, and in turn, those insects lured bats. Villagers noticed the presence of bats swooping through the flickering flames, hunting for insects in the darkness. Over time, these nocturnal creatures became linked with the spirit world and the supernatural due to their frequent appearances during Samhain.</p>

        <h2>From Samhain to Halloween</h2>
        <p>As the centuries passed, Samhain evolved into what we now know as Halloween. Many of the ancient traditions survived, albeit in altered forms. During the Middle Ages, bats already had a sinister reputation—not only due to their association with Samhain but also because of their nocturnal nature and connection to darkness.</p>

        <p>In medieval times, legends of witches also began to spread, and it was believed that they could transform into animals like black cats and, of course, bats. This association further cemented the link between bats and the supernatural world.</p>

        <h2>Bats in Folklore and Popular Culture</h2>
        <p>Over time, bats found their place in folklore and gothic literature. Writers like Bram Stoker, with his famous novel Dracula, solidified the notion of bats as creatures of evil. The existence of the vampire bat, a real species that feeds on blood, also contributed to this image. The idea of vampires transforming into bats to stalk their prey became one of the most iconic symbols of horror.</p>

        <p>Today, bats are a key figure in Halloween celebrations. They appear in decorations, costumes, and films, symbolizing the mystery, magic, and fear that surrounds this holiday.</p>

        <h2>Bats: Modern Symbols of Halloween</h2>
        <p>Despite their dark associations, bats play an essential role in nature, controlling insect populations and pollinating plants. However, in popular imagination, they continue to be enigmatic creatures tied to fear and the night. Their silent flight through the dark, their connection to ancient festivals like Samhain, and their role in folklore have kept bats as a central symbol of Halloween.</p>

      `,
      category:"Halloween",
      showProducts: true, // No mostrar productos en este artículo
    }
  };

  // Obtener el artículo correspondiente según el ID
  const article = articles[articleId];

  if (!article) {
    return <p>Not found</p>; // Manejo de error si no se encuentra el artículo
  }

  return (
    <div className="article-page container">
      <header className="article-header">
        <h1 className="article-title">{article.title}</h1>
      </header>

      {/* Imagen centrada con un alto fijo de 200px */}
      <div className="article-image-container">
        <img src={article.image} alt={article.title} className="article-image-fixed-height" />
      </div>

      {/* Contenido HTML */}
      <main className="article-content" dangerouslySetInnerHTML={{ __html: article.content }}>
      </main>

      {/* Mostrar productos solo si la bandera "showProducts" es true */}
      {article.showProducts && (
        <section className="products-section">
          <h3>Check Out These Products</h3>
          <ProductGrid products={productsData} category={article.category} />
        </section>
      )}

      <footer className="article-footer">
        <a href="/blog" className="back-link">Back to the blog</a>
      </footer>
    </div>
  );
};

export default BlogArticlePage;
