import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Importar Link para navegación
import '../SideMenu.css';

const SideMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Cierra el menú si se hace clic fuera de él
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (isOpen && !event.target.closest('.side-menu') && !event.target.closest('.hamburger')) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleOutsideClick);
    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  return (
    <div>
      {/* Icono de "hamburger" para abrir/cerrar el menú */}
      <div className={`hamburger ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>

      {/* Menú lateral */}
      <nav className={`side-menu ${isOpen ? 'open' : ''}`}>
        <button className="close-btn" onClick={toggleMenu}>X</button> {/* Mueve el botón "X" a la derecha */}
        <ul>
          <li><Link to="/" onClick={toggleMenu}>Home</Link></li>
          <li><Link to="/blog" onClick={toggleMenu}>Blog</Link></li>
        </ul>
      </nav>
    </div>
  );
};

export default SideMenu;