// ProductGridSearch.js
import React from 'react';
import ProductCard from './ProductCard';

function ProductGridSearch({ products }) {
  // Limitar la cantidad de productos a 16
  const displayedProducts = products.slice(0, 12);

  return (
    <div className="product-grid-search">
      {displayedProducts.map(product => (
        <ProductCard key={product.id} product={product} />
      ))}
    </div>
  );
}

export default ProductGridSearch;
