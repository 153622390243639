// components/ProductCard.js
import React from 'react';
import '../styles.css';

// Ejemplo de cómo mostrar el descuento en tu componente
function ProductCard({ product }) {
    const productImage = require(`../images/${product.image}`);
  
    return (
      <div className="product-card">
        <a href={product.url} target="_blank" rel="noopener noreferrer">
          <img src={productImage} alt={product.name} />
          <h3>{product.name}</h3>
          <p>{product.description}</p>
          <div className="price">
            ${product.price.toFixed(2)} 
            {product.discount ? (
              <span className="discount"> - {product.discount}% off</span>
            ) : null}
          </div>
          <button>Buy Now</button>
        </a>
      </div>
    );
  }
  

export default ProductCard;
