import React from 'react';
import '../styles.css';  // Asegúrate de que la ruta del CSS es correcta
import '@fortawesome/fontawesome-free/css/all.min.css';
import profileImage from '../images/logo.jpeg';  // Importa la imagen directamente

function ProfileCard() {
  return (
    <div className="profile-card">
      <img 
        src={profileImage} 
        alt="Profile" 
        className="profile-image" 
      />
      <div className="profile-info">
        <h2 className="store-name">Innovate Unboxed</h2>
        <div className="social-links">
            <a href="https://www.youtube.com/@InnovateUnboxed" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-youtube"></i>
            </a>
            <a href="https://www.instagram.com/innovate_unboxed/?hl=en" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-instagram"></i>
            </a>
            <a href="https://www.tiktok.com/@innovate_unboxed" target="_blank" rel="noopener noreferrer">
                <i className="fab fa-tiktok"></i>
            </a>
        </div>
        <p className="store-description">Discover a unique selection of standout products from Amazon. We focus on finding special deals and items that can make your life easier and more enjoyable. Explore our recommendations and find what you need on Amazon!</p>
      </div>
    </div>
  );
}

export default ProfileCard;
